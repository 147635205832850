import { css } from "styled-components"

// Styling
import { fonts } from "./fonts"
import mq from "./breakpoints"

// Text styles
export default {
  pageTitle: css`
    margin: 0 0 8px;
    font-family: ${fonts.primary};
    font-size: 64px;
    font-weight: 700;
    line-height: 1.125;

    ${mq.from.M`
      margin: 0 0 12px;
      font-size: 96px;
    `};
  `,
  pageSubtitle: css`
    margin: 0 0 24px;
    font-family: ${fonts.primary};
    font-size: 40px;
    font-weight: 600;
    line-height: 1;

    ${mq.from.M`
      margin: 0 0 32px;
      font-size: 48px;
    `};
  `,
  title: css`
    margin: 0 0 20px;
    font-family: ${fonts.primary};
    font-size: 40px;
    font-weight: 700;
    line-height: 1.125;

    ${mq.from.M`
      margin: 0 0 24px;
      font-size: 48px;
    `};
  `,
  subtitle: css`
    margin: 0 0 20px;
    font-family: ${fonts.primary};
    font-size: 24px;
    font-weight: 700;
    line-height: 1.125;

    ${mq.from.M`
      margin: 0 0 24px;
      font-size: 32px;
    `};
  `,
  intro: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;

    ${mq.from.M`
      font-size: 24px;
    `}
  `,
  body: css`
    margin: 0 0 20px;
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;

    ${mq.from.M`
      font-size: 18px;
      margin-bottom: 24px;
    `}
  `,
}

import { css } from "styled-components"

// Fonts
const fonts = {
  primary: "'Poppins', sans-serif",
}

const fontFaces = css`
  @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
`

export { fonts, fontFaces }

import React, { useState, ReactNode } from "react"
import styled from "styled-components"

// Context
import { AppContext } from "../../context/AppContext"

// Styling
import CSSReset from "../../styles/reset"
import GlobalStyle from "../../styles/"

interface IProps {
  children: ReactNode
  className?: string
}

const AppWrapper = ({ children, className }: IProps) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  return (
    <>
      <CSSReset />
      <GlobalStyle />
      <AppContext.Provider
        value={{
          scrollPosition,
          setScrollPosition: position => setScrollPosition(position),
        }}
      >
        <Main className={className}>{children}</Main>
      </AppContext.Provider>
    </>
  )
}

const Main = styled.main`
  position: relative;
  z-index: 1;
  min-height: 100vh;
`

export default AppWrapper

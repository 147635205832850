/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"

// Components
import AppWrapper from "./src/components/wrappers/AppWrapper"

export const wrapPageElement = ({ element, props }) => {
  return <AppWrapper {...props}>{element}</AppWrapper>
}
